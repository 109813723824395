<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="14" r="14" fill="black" />
    <g clip-path="url(#clip0_5006_8611)">
      <path
        d="M20.9279 17.724L18.6168 20.1654C18.5666 20.2184 18.5058 20.2607 18.4382 20.2896C18.3707 20.3185 18.2978 20.3334 18.2242 20.3333H7.26831C7.21603 20.3333 7.16489 20.3183 7.12118 20.2901C7.07746 20.2618 7.04307 20.2216 7.02224 20.1744C7.0014 20.1272 6.99502 20.075 7.00389 20.0242C7.01275 19.9735 7.03647 19.9264 7.07214 19.8887L9.385 17.4473C9.43511 17.3944 9.4957 17.3522 9.56303 17.3234C9.63036 17.2945 9.703 17.2795 9.77645 17.2794H20.7317C20.784 17.2794 20.8351 17.2944 20.8789 17.3226C20.9225 17.3509 20.9569 17.3911 20.9778 17.4383C20.9986 17.4855 21.005 17.5377 20.9961 17.5885C20.9872 17.6392 20.9635 17.6863 20.9279 17.724ZM18.6168 12.8076C18.5666 12.7546 18.5058 12.7123 18.4382 12.6834C18.3707 12.6545 18.2978 12.6396 18.2242 12.6397H7.26831C7.21603 12.6397 7.16489 12.6547 7.12118 12.683C7.07746 12.7112 7.04307 12.7514 7.02224 12.7986C7.0014 12.8459 6.99502 12.898 7.00389 12.9488C7.01275 12.9995 7.03647 13.0466 7.07214 13.0843L9.385 15.5257C9.43511 15.5786 9.4957 15.6208 9.56303 15.6497C9.63036 15.6786 9.703 15.6935 9.77645 15.6936H20.7317C20.784 15.6936 20.8351 15.6786 20.8789 15.6504C20.9225 15.6221 20.9569 15.5819 20.9778 15.5347C20.9986 15.4875 21.005 15.4353 20.9961 15.3845C20.9872 15.3338 20.9635 15.2867 20.9279 15.2491L18.6168 12.8076ZM7.26831 11.054H18.2242C18.2978 11.054 18.3707 11.0391 18.4382 11.0102C18.5058 10.9813 18.5666 10.939 18.6168 10.886L20.9279 8.44459C20.9635 8.40694 20.9872 8.35985 20.9961 8.3091C21.005 8.25836 20.9986 8.20617 20.9778 8.15894C20.9569 8.11172 20.9225 8.07152 20.8789 8.04328C20.8351 8.01505 20.784 8 20.7317 8H9.77645C9.703 8.00012 9.63036 8.01509 9.56303 8.04399C9.4957 8.07288 9.43511 8.11508 9.385 8.16797L7.07273 10.6094C7.0371 10.647 7.01339 10.694 7.00451 10.7447C6.99562 10.7954 7.00195 10.8476 7.02271 10.8948C7.04348 10.942 7.07777 10.9822 7.1214 11.0104C7.16502 11.0387 7.21608 11.0538 7.26831 11.054Z"
        :fill="`url(#${uniqueId})`"
      />
    </g>
    <defs>
      <linearGradient
        :id="uniqueId"
        x1="8.18176"
        y1="20.6273"
        x2="19.4768"
        y2="7.70056"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.08" stop-color="#9945FF" />
        <stop offset="0.3" stop-color="#8752F3" />
        <stop offset="0.5" stop-color="#5497D5" />
        <stop offset="0.6" stop-color="#43B4CA" />
        <stop offset="0.72" stop-color="#28E0B9" />
        <stop offset="0.97" stop-color="#19FB9B" />
      </linearGradient>
      <clipPath id="clip0_5006_8611">
        <rect width="14" height="12.3333" fill="white" transform="translate(7 8)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  data() {
    return {
      uniqueId: 'svg-' + Date.now() + '-' + Math.random().toString(36).substr(2, 9)
    }
  }
}
</script>
