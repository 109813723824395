// import { createAppKit, useAppKit } from '@reown/appkit/vue'
// import { Ethers5Adapter } from '@reown/appkit-adapter-ethers5'
// import { mainnet, arbitrum } from '@reown/appkit/networks'
import * as configMap from '@exsat/common/config.js'
// import { defineChain } from '@reown/appkit/networks'

import { computed, inject, ref, watch, toRaw } from 'vue'

import { Checksum256 } from '@wharfkit/antelope'

import { BtcIcon, EthIcon, SolIcon, TronIcon } from '../icons'

import { getPublicClient, getBalance, getWalletClient } from '@wagmi/core'

import { getContract } from 'viem'

import {
  displayValue,
  parseInputValue,
  calculateHash,
  convertAddress,
  bytesToHex,
  truncateToEightDecimals
} from '@exsat/common/utils.js'

import TrxTokenAbi from '../abi/FiatToken.json'

import EthTokenAbi from '../abi/EthToken.json'

import { useLocalStorage } from '@/hooks/useLocalStorage'

const { value: isTips, setValue: setTips } = useLocalStorage('sendAssetsTips', true)

function capitalizeFirstLetter(str) {
  if (str.length === 0) return str // 如果字符串为空，直接返回原字符串
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * 使用 WalletConnect 连接钱包
 *
 * @returns 包含 appKitModal 的对象
 */
export default function useWalletconnect(isWallet) {
  // 注入的 watchChanges 方法，用于监听钱包连接状态变化+1
  const watchChanges = inject('watchChanges')

  const account = inject('account')

  const contractKit = inject('contractKit')

  const config = inject('config')

  const wagmiConfig = inject('wagmiConfig')

  const toast = inject('toast')

  // 资产地址的响应式引用
  const assetsAddress = ref()

  const tokenBalance = ref('')

  const processing = ref(false)

  // 定义当前硬币的变量，默认为'BTC'
  const currentCoin = ref('BTC')

  const coinsTotalPriceMap = ref({})

  const totalBtcSupply = ref(0)

  const withdrawAddr = ref('')

  const withdrawAmount = ref('')

  const tokenBalanceMap = ref({})

  const multipleCoinMap = ref({
    OBitcoin: [
      {
        source_chain_name: 'OBitcoin',
        source_name: 'Bitcoin',
        source_symbol: 'BTC',
        permissionId: config.cactusBridgePermissionId,
        destAddress: '',
        decimals: 8
      }
    ]
  })

  // 当前选中的菜单项
  const currentChainName = ref('OBitcoin')

  // 当前选中的菜单项的配置信息
  const currentChainConfig = computed(() => {
    const chain = multipleCoinMap.value[currentChainName.value]

    console.log('chain', chain)

    console.log('currentCoin', currentCoin.value)

    return (
      chain.find(
        (item) => capitalizeFirstLetter(item.source_symbol.toString()) === currentCoin.value
      ) || {}
    )
  })

  // 定义下拉菜单选项列表
  const localTokenIcons = [
    {
      label: 'OBitcoin',
      icon: BtcIcon
    },
    {
      label: 'Bitcoin',
      icon: BtcIcon
    },
    {
      label: 'Btc',
      icon: BtcIcon
    },
    {
      label: 'Sepolia',
      icon: EthIcon
    },
    {
      label: 'Ethereum',
      icon: EthIcon
    },
    {
      label: 'Tron',
      icon: TronIcon
    },
    {
      label: 'Solana',
      icon: SolIcon
    }
  ]
  // 当前支持链的列表，只包含有配置信息的资产
  const tokenIcons = computed(() => {
    return localTokenIcons.filter((item) => {
      return multipleCoinMap.value[item.label]
    })
  })

  const coinIconsMap = computed(() => {
    const map = {}

    for (const element of tokenIcons.value) {
      map[element.label] = element
    }
    return map
  })

  const allTokenBalance = computed(() => {
    return Object.values(tokenBalanceMap.value) || []
  })

  // 创建 appKitModal
  function createAppKitModel() {
    // 3. 监听钱包连接状态变化
    watchChanges()
    // createAppKit({
    //   adapters: [new Ethers5Adapter()],
    //   networks: [defineChain(exsatConfig.chainConfig)],
    //   projectId,
    //   features: {
    //     analytics: true,
    //     connectMethodsOrder: ['wallet']
    //   }
    // })
    // const appKitModal = useAppKit()
    // return appKitModal
  }
  // 创建地址
  async function createAddress(chainName, sig) {
    const { permissionId = '0' } = currentChainConfig.value || {}

    const data = {
      jsonrpc: '2.0',
      id: 0,
      method: 'RegUser',
      params: [permissionId, account.value, 'remark', sig]
    }
    // Default options are marked with *
    const response = await fetch(config.cbridge_url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
    return response.json() // parses JSON response into native JavaScript objects
  }
  // 获取资产地址
  async function getAssetsAddress() {
    const { permissionId = '0' } = currentChainConfig.value || {}

    // 加载指定合约
    const contract = await contractKit.load('cbridge.xsat')
    // 获取合约中的表
    const table = contract.table('addrmappings', permissionId)

    const keyword = await calculateHash(account.value + '-remark', 'lower')

    const index = Checksum256.from(keyword)

    const cursor = table.query({
      reverse: true,
      index_position: 'fourth',
      key_type: 'sha256',
      from: index,
      to: index
    })
    const records = await cursor.all()

    console.log('addrmappings 表：', records)

    const address = records.find(
      (item) => item.recipient_address.toUpperCase() === account.value.toUpperCase()
    )?.deposit_address

    // 返回结果中的deposit_address
    return address
  }
  // 获取合约中的代币列表
  async function getContractCoins() {
    // 加载指定合约
    const contract = await contractKit.load('cbridge.xsat')
    // 获取合约中的token表
    const token = contract.table('token', 'cbridge.xsat')
    const cursor = token.query()
    const records = await cursor.all()

    // 获取合约中的permissions表
    const permissions = contract.table('permissions', 'cbridge.xsat')

    const pTable = permissions.query()
    const pTableList = await pTable.all()

    const tokenMap = {}

    // 遍历 permissions 表，将每个 chain_names 中的元素转换为大写首字母形式，并存入 tokenMap 中
    for (const token of pTableList) {
      const id = token.id.toString()
      const coinNames = token.chain_names.map((v) => capitalizeFirstLetter(v.toString()))
      for (const element of coinNames) {
        if (tokenMap[element]) {
          continue
        }
        tokenMap[element] = {
          permissionId: id,
          coinNames: coinNames
        }
      }
    }
    // 遍历 token 表，将每个 source_chain_name 的值转换为大写首字母形式，并存入 multipleCoinMap 中

    for (const coin of records) {
      const name = capitalizeFirstLetter(coin.source_chain_name.toString())

      if (!multipleCoinMap.value[name]) {
        multipleCoinMap.value[name] = []
      }
      multipleCoinMap.value[name] = [
        ...multipleCoinMap.value[name],
        {
          ...coin,
          decimals: Number(coin.decimals.toString()),
          permissionId: tokenMap[name]?.permissionId
        }
      ]
    }

    console.log('multipleCoinMap', multipleCoinMap.value)

    if (isWallet) {
      getAllTokenBalance()
    }
  }

  async function getAllTokenBalance() {
    if (!account.value) return

    const data = multipleCoinMap.value

    const publicClient = await getPublicClient(wagmiConfig)

    tokenBalanceMap.value = {}

    const getBalance = async (coin, chain) => {
      const symbol = coin.source_symbol

      try {
        const contract = getContract({
          address: coin.dest_contract,
          abi: TrxTokenAbi,
          client: publicClient
        })

        const r = await contract.read.balanceOf([account.value])

        const decimals = coin.decimals

        tokenBalanceMap.value[symbol + chain] = {
          symbol,
          icon: coinIconsMap.value[chain].icon,
          balance: truncateToEightDecimals(displayValue(r.toString(), decimals))
        }

        console.log('tokenBalanceMap.value', tokenBalanceMap.value)
      } catch (error) {
        console.error('error', error)
        tokenBalanceMap.value[symbol] = {
          symbol,
          icon: coinIconsMap.value[chain].icon,
          balance: 0
        }
      }
    }

    for (const key in data) {
      if (!['Bitcoin', 'Btc', 'OBitcoin'].includes(key)) {
        const list = data[key]
        for (const coin of list) {
          getBalance(coin, key)
        }
      }
    }
  }

  async function getTokenValues(index) {
    try {
      if (index === 0) return
      if (!account.value) {
        return
      }

      if (currentChainName.value.includes('Bitcoin') || currentChainName.value.includes('Btc')) {
        getBalance(wagmiConfig, { address: account.value }).then((r) => {
          tokenBalance.value = displayValue(r.value.toString(), r.decimals)
          console.log('OBitcoin tokenBalance', tokenBalance.value)
        })
        return
      }

      const publicClient = await getPublicClient(wagmiConfig)

      const contract = getContract({
        address: currentChainConfig.value.dest_contract,
        abi: TrxTokenAbi,
        client: publicClient
      })

      const r = await contract.read.balanceOf([account.value])

      const decimals = currentChainConfig.value.decimals

      tokenBalance.value = displayValue(r.toString(), decimals)

      console.log('tokenBalance', tokenBalance.value)
    } catch (error) {
      tokenBalance.value = ''
      toast.error({
        title: 'Failed',
        context: error.shortMessage ? error.shortMessage : JSON.stringify(error, null, 2)
      })
    }
  }

  /**
   * 提现转账函数
   *
   * 该函数用于处理提现转账的相关逻辑。
   */
  async function withdrawTransfer(address, amount) {
    console.log('withdrawTransfer 地址：', currentChainConfig.value.call_contract)
    console.log('该函数用于处理提现转账的相关逻辑', currentChainConfig.value)

    processing.value = true

    const decimals = currentChainConfig.value.decimals

    const resAmount = parseInputValue(amount, decimals)

    try {
      const walletClient = await getWalletClient(wagmiConfig)

      const hash1 = await walletClient.writeContract({
        address: currentChainConfig.value.dest_contract,
        abi: TrxTokenAbi,
        functionName: 'approve',
        args: [currentChainConfig.value.call_contract, resAmount]
      })

      console.log('approve', hash1)

      const params = {
        _permissionId: currentChainConfig.value.permissionId,
        _chainName: currentChainConfig.value.source_chain_name.toString(),
        _recipientAddress: address,
        _amount: resAmount,
        _remark: ''
      }

      console.log('withdrawTransfer params：', Object.values(params))

      const hash2 = await walletClient.writeContract({
        address: currentChainConfig.value.call_contract,
        abi: EthTokenAbi,
        functionName: 'withdraw',
        args: Object.values(params)
      })

      const link = config.links.explorer + '/tx/' + hash2

      toast.success({
        title: 'Transaction Sent',
        context: `Tx Hash: <a href="${link}">${hash2}</a>`
      })
    } catch (error) {
      toast.error({
        title: 'Transaction Failed',
        context: error.shortMessage ? error.shortMessage : JSON.stringify(error, null, 2)
      })
    } finally {
      processing.value = false
    }
  }

  async function getCurrentCoinPrice(chainConfig, chainName) {
    try {
      const { source_symbol: symbol } = chainConfig
      const keyname = `${symbol}_${chainName}`

      coinsTotalPriceMap.value[keyname] = {
        singlePrice: 0,
        totalPrice: 0
      }

      console.log('getCurrentCoinPrice', symbol, chainName)

      const priceInfo = await fetch(
        `https://api.binance.com/api/v3/ticker/price?symbol=${symbol}USDT`
      ).then((res) => res.json())

      if (priceInfo.price) {
        const price = Number(priceInfo.price)
        if (['OBitcoin', 'Bitcoin', 'Btc'].includes(chainName)) {
          coinsTotalPriceMap.value[keyname] = {
            singlePrice: price,
            totalPrice: 0
          }
          return
        }
        const publicClient = getPublicClient(wagmiConfig)
        const contract = getContract({
          address: chainConfig.dest_contract,
          abi: TrxTokenAbi,
          client: publicClient
        })
        const count = await contract.read.totalSupply()

        const currentPrice = price * Number(BigInt(count / BigInt(10 ** chainConfig.decimals)))

        coinsTotalPriceMap.value[keyname] = {
          singlePrice: price,
          totalPrice: currentPrice
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  function getTotalBalance() {
    for (const chainName in multipleCoinMap.value) {
      const coinList = multipleCoinMap.value[chainName]

      for (const coin of coinList) {
        getCurrentCoinPrice(toRaw(coin), chainName)
      }
    }
  }

  // 假设这是处理的框架和函数

  // 模拟 BTC 网络提现（带memo）
  async function withdrawBTC(address, refresh) {
    // 这里假设是通过 BTC 网络的旧桥进行提现操作
    if (!address || !refresh) {
      throw new Error('Invalid address or memo')
    }
    console.log(`Withdrawing BTC to ${address}`, convertAddress(address))
    // 执行BTC提现操作
    processing.value = true

    const resAmount = parseInputValue(withdrawAmount.value, 18)

    try {
      const publicClient = await getPublicClient(wagmiConfig)

      const memo = [
        currentChainConfig.value.permissionId,
        currentChainConfig.value.source_chain_name,
        withdrawAddr.value,
        ''
      ].join(',')

      const walletClient = await getWalletClient(wagmiConfig)
      const hash = await walletClient.sendTransaction({
        from: account.value,
        to: convertAddress('cproxy.xsat'),
        value: resAmount,
        data: bytesToHex(new TextEncoder().encode(memo))
      })

      await publicClient.waitForTransactionReceipt({
        confirmations: 1,
        hash
      })

      const link = config.links.explorer + '/tx/' + hash

      toast.success({
        title: 'Transaction Sent',
        context: `Tx Hash: <a href="${link}">${hash}</a>`
      })
    } catch (e) {
      toast.error({
        title: 'Transaction Failed',
        context: e.shortMessage ? e.shortMessage : JSON.stringify(e, null, 2)
      })
    } finally {
      refresh()
      processing.value = false
    }
  }

  // 模拟 ERC20 代币提现
  async function withdrawERC20(address, refresh) {
    // 处理erc2o.xsat的提现逻辑
    if (!address || !refresh) {
      throw new Error('Invalid address or memo')
    }
    console.log(`Withdrawing ERC20 token to ${address} `)
    // 执行ERC20提现操作，可能涉及与ERC20智能合约交互的代码
    processing.value = true

    const decimals = currentChainConfig.value.decimals

    const resAmount = parseInputValue(withdrawAmount.value, decimals)

    try {
      const publicClient = await getPublicClient(wagmiConfig)
      // id,evm,btc,speed
      const memo = [
        currentChainConfig.value.permissionId,
        account.value,
        withdrawAddr.value,
        'slow'
      ].join(',')
      const walletClient = await getWalletClient(wagmiConfig)
      const hash = await walletClient.sendTransaction({
        from: account.value,
        to: convertAddress('erc2o.xsat'),
        value: resAmount,
        data: bytesToHex(new TextEncoder().encode(memo))
      })

      await publicClient.waitForTransactionReceipt({
        confirmations: 1,
        hash
      })

      const link = config.links.explorer + '/tx/' + hash

      toast.success({
        title: 'Transaction Sent',
        context: `Tx Hash: <a href="${link}">${hash}</a>`
      })
    } catch (e) {
      toast.error({
        title: 'Transaction Failed',
        context: e.shortMessage ? e.shortMessage : JSON.stringify(e, null, 2)
      })
    } finally {
      refresh()
      processing.value = false
    }
  }

  // 主函数，决定哪个提现函数应该被调用
  function withdrawBasedOnAddress(refresh) {
    const address = currentChainConfig.value.dest_contract

    if (!address) return

    const coin = currentChainConfig.value.source_symbol

    console.log('withdrawBasedOnAddress', address, coin, currentChainConfig.value)

    if (address.length <= 12) {
      // 判断地址的类型，选择对应的提现函数
      if (coin === 'BTC') {
        return withdrawBTC(address, refresh) // 处理BTC提现
      }
      return withdrawERC20(address, refresh) // 处理ERC20提现
    }
    return
  }

  async function initData() {
    await getContractCoins()
    !isWallet && getTotalBalance()
  }

  initData()

  const totalAssetsPrice = computed(() => {
    let total = 0
    let btcPrice = 0
    const dataMap = coinsTotalPriceMap.value
    for (const key in dataMap) {
      const info = dataMap[key]
      let totalPrice = info.totalPrice

      if (key == 'BTC_OBitcoin') {
        btcPrice = info.singlePrice * Number(totalBtcSupply.value)
      } else {
        total += totalPrice
      }
    }

    if (total == 0 || btcPrice == 0) {
      return '$0.00M'
    }

    return '$' + Number(Number(total + Number(btcPrice)) / 1000000).toFixed(2) + 'M'
  })

  watch(
    () => account.value,
    () => {
      if (allTokenBalance.value.length === 0) {
        if (isWallet) {
          getAllTokenBalance()
        }
      }
    }
  )

  return {
    createAppKitModel,
    createAddress,
    getAssetsAddress,
    getContractCoins,
    getTokenValues,
    tokenIcons,
    assetsAddress,

    tokenBalance,
    currentChainName,
    processing,
    withdrawTransfer,
    currentChainConfig,
    multipleCoinMap,
    localTokenIcons,
    currentCoin,
    capitalizeFirstLetter,
    isTips,
    setTips,
    coinsTotalPriceMap,
    totalBtcSupply,
    totalAssetsPrice,
    withdrawAddr,
    withdrawAmount,
    allTokenBalance,
    getAllTokenBalance,
    withdrawBasedOnAddress
  }
}
