import BouncingDots from '@exsat/common/components/BouncingDots.vue'
import Icon from '@exsat/common/components/Icon.vue'
import Skeleton from '@exsat/common/components/Skeleton.vue'

import ModalController from '@exsat/common/components/Modal.js'

import * as configMap from '@exsat/common/config.js'
import dayjs from '@exsat/common/dayjs.js'

import Toast from '@exsat/common/toast.js'
import { displayTokenValue } from '@exsat/common/utils.js'
import { injected } from '@wagmi/connectors'
import { connect, createConfig, http, reconnect, switchChain } from '@wagmi/core'
import { APIClient } from '@wharfkit/antelope'
import { ContractKit } from '@wharfkit/contract'

import { Col, ConfigProvider, Flex, Layout, Row, Tooltip } from 'ant-design-vue'

import { orderBy } from 'lodash-es'
import { defineChain } from 'viem'
import { createApp, ref } from 'vue'

import VueGtag from 'vue-gtag'
import App from './App.vue'
import router from './router.js'

const app = createApp(App)
const components = [Flex, Row, Col, Layout, Layout.Content, Layout.Footer, Tooltip, ConfigProvider]
const install = (app) => {
  components.forEach((component) => {
    app.component(component.name, component)
  })
}

const exsatConfig = configMap[localStorage.exsatNetwork] || configMap.mainnet
const contractKit = new ContractKit({ client: new APIClient({ url: exsatConfig.contractUrl }) })
const wagmiConfig = createConfig({
  chains: [defineChain(exsatConfig.chainConfig)],
  transports: {
    [exsatConfig.chainConfig.id]: http()
  },
  connectors: []
})
localStorage.setItem('exsatApiBase', exsatConfig.apiBase)
console.log('network', exsatConfig.apiBase)

const account = ref(localStorage.exsatAccount || '')

app.config.globalProperties.getSvg = function getSvg(name) {
  return new URL(`./assets/img/${name}.svg`, import.meta.url).href
}
app.config.globalProperties.$day = dayjs
app.config.globalProperties.$ordinal = (n) => {
  if (!n || !Number.isInteger(Number(n))) {
    return ''
  }
  return (
    n +
    { e: 'st', o: 'nd', w: 'rd', h: 'th' }[
      new Intl.PluralRules('en', { type: 'ordinal' }).select(n)[2]
    ]
  )
}

app.config.globalProperties.$tokenValue = displayTokenValue
app.provide('$tokenValue', displayTokenValue)

app.component('Ske', Skeleton)
app.component('BouncingDots', BouncingDots)
app.component('Icon', Icon)

app.provide('contractKit', contractKit)
app.provide('wagmiConfig', wagmiConfig)

app.provide('config', exsatConfig)
app.provide('updateConfig', (val) => (exsatConfig.value = val))

app.provide('checkConnection', checkConnection)

app.provide('watchChanges', watchChanges)

app.provide('toast', Toast)

const pendingTxsState = ref(0)

app.provide('pendingTxsState', pendingTxsState)

app.provide('updatePendingTxsState', (val) => (pendingTxsState.value = val))

/* Usage
 const toast = inject('toast');
 toast.success({title: 'xxx', context: '...support html...'})
 toast.error({title: 'xxx', context: '...support html...', duration: 5000})
 */

app.provide('account', account)
app.provide('updateAccount', (val) => (account.value = val))

app.provide('getSortedData', (array, order, cb) => {
  if (!array) {
    return []
  }
  if (typeof cb === 'function') {
    array = cb(array)
  }
  if (!order) {
    return array
  }
  const field = order.replace(/^-/, '')
  const orders = order.startsWith('-') ? 'desc' : 'asc'
  return orderBy(array, (i) => parseFloat(i[field]) ?? i[field], [orders])
})
app.provide('modal', ModalController)

app.use(router)
app.use(install)
app.mount('#app')

app.use(
  VueGtag,
  {
    config: { id: 'G-PM1QBCN4QS' }
  },
  router
)

checkConnection(true)

/**
 * 检查与钱包的连接状态，并根据需要尝试重新连接或连接新钱包。
 *
 * @param reconnectOnly 是否仅尝试重新连接当前钱包。如果为true，则不会尝试连接新钱包。
 * @returns 无返回值。
 */
async function checkConnection(reconnectOnly) {
  console.log('Checking connection')
  // 如果 account.value 存在，则直接返回
  if (account.value) {
    return
  }

  // 尝试重新连接
  let r = await reconnect(wagmiConfig)
  // 如果重新连接成功且获取到了账户信息
  if (r.length > 0 && r[0].accounts.length > 0) {
    // 检查链ID
    checkChainID(r[0].chainId)
    // 更新 account.value
    account.value = r[0].accounts[0]
    // 监听变化
    watchChanges()
    return
  }

  // 如果不是仅重新连接模式
  if (!reconnectOnly) {
    try {
      // 尝试连接
      r = await connect(wagmiConfig, { connector: injected() })
    } catch (e) {
      // 如果捕获到 ProviderNotFoundError 错误
      if (e.name == 'ProviderNotFoundError') {
        // 提示用户安装支持的钱包，如 Metamask
        window.alert('Please install a supported wallet such as Metamask to connect.')
      }
      return
    }
    // 检查链ID
    checkChainID(r.chainId)
    // 如果获取到了账户信息
    if (r.accounts.length > 0) {
      console.log('login')
      // 更新 account.value
      account.value = r.accounts[0]
      // 监听变化
      watchChanges()
    }
  }
}

/**
 * 检查链ID是否与预期链ID匹配，不匹配则提示用户切换到正确的链
 *
 * @param {string} chainid - 当前链ID
 * @returns {Promise<void>} 无返回值
 */
async function checkChainID(chainid) {
  // 获取目标链ID
  let targetChainid = wagmiConfig.chains[0].id

  // 判断传入的链ID是否与目标链ID相同
  if (chainid !== targetChainid) {
    // 如果不相同，弹出提示框提示用户切换到正确的链
    window.alert('Please switch to the correct chain to continue!')

    // 调用switchChain函数切换到目标链
    switchChain(wagmiConfig, { chainId: targetChainid }).then(() => {
      // 切换链成功后刷新页面
      window.location.reload()
    })
  }
}

/**
 * 监控以太坊钱包的变化
 *
 * 由于某些包的bug，如果可用，我们将使用旧的注入的provider
 */
function watchChanges() {
  // Due to package bugs, we have to use the old injected povider if available
  // 由于包错误，如果可用，我们必须使用旧的注入的提供者
  if (window.ethereum) {
    // 监听链ID变化事件
    // Listen for chain ID changes
    window.ethereum.on('chainChanged', (chainId) => {
      // 检查链ID
      // Check the chain ID
      checkChainID(Number(chainId))
    })

    // 监听账户变化事件
    // Listen for account changes
    window.ethereum.on('accountsChanged', (accounts) => {
      // 如果账户列表长度大于0
      // If the length of the account list is greater than 0
      if (accounts.length > 0) {
        // 更新账户值为第一个账户
        // Update the account value to the first account
        account.value = accounts[0]
      }
    })
  }
}

window.toast = Toast
