<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5006_8601)">
      <path
        d="M27.9999 14C27.9999 16.7689 27.1789 19.4757 25.6405 21.778C24.1022 24.0803 21.9157 25.8747 19.3575 26.9343C16.7993 27.9939 13.9844 28.2712 11.2687 27.731C8.55295 27.1908 6.05839 25.8574 4.10045 23.8995C2.14252 21.9416 0.809144 19.447 0.268951 16.7313C-0.271242 14.0155 0.00600489 11.2006 1.06563 8.64243C2.12526 6.08427 3.91967 3.89777 6.22196 2.35943C8.52425 0.821086 11.231 0 13.9999 0C17.713 0 21.2739 1.475 23.8994 4.10051C26.525 6.72602 27.9999 10.287 27.9999 14Z"
        fill="#F7931A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.95016 6.39291L13.1676 7.25454L13.8867 4.57418L15.4954 5.01073L14.8043 7.58036L16.1165 7.93291L16.8089 5.33527L18.4456 5.77309L17.7405 8.38473C17.7405 8.38473 20.4132 8.97654 21.042 11.1504C21.6707 13.3242 19.6598 14.4658 19.0387 14.5091C19.0387 14.5091 21.3805 15.7933 20.5762 18.3196C19.7718 20.846 17.3027 21.2978 14.7051 20.7187L14 23.4271L12.3632 22.9893L13.0823 20.3229L11.7842 19.9691L11.0651 22.6545L9.44107 22.218L10.1614 19.5453L6.8587 18.6544L7.69107 16.8064C7.69107 16.8064 8.6227 17.0609 8.97525 17.1449C9.32779 17.2289 9.55434 16.8624 9.65361 16.4958C9.75289 16.1293 11.2483 10.0545 11.3896 9.55436C11.5309 9.05418 11.4736 8.66345 10.8805 8.50945C10.2874 8.35545 9.48052 8.11491 9.48052 8.11491L9.95016 6.39291ZM13.1956 14.4378L12.3047 17.9798C12.3047 17.9798 16.7223 19.5745 17.2874 17.3307C17.8525 15.0869 13.1956 14.4378 13.1956 14.4378ZM13.6054 12.7578L14.4798 9.51236C14.4798 9.51236 18.2623 10.1895 17.7965 11.9955C17.3307 13.8015 15.1009 13.1091 13.6054 12.7578Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5006_8601">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
